import axios from 'axios';

const mernApi = axios.create();

// Set your own baseURL
mernApi.defaults.baseURL = process.env.APP_URL;

mernApi.setAuthToken = (jwtToken) => {
  mernApi.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
};

export default mernApi;
