import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AnonymousRoute from '../../components/accessControl/AnonymousRoute';
// import ProtectedRoute from '../../components/accessControl/ProtectedRoute';
import CreditCardForm from '../Tokenization/CreditCardForm';
import UnauthorizedPage from '../Tokenization/CreditCardForm';
import { tryLocalSignIn } from '../../store/actions';
// import { getIsSignedIn } from '../../store/selectors';

class App extends React.Component {
  componentDidMount() {
    this.props.tryLocalSignIn();
  }

  render() {
    return (
      <Switch>
          <AnonymousRoute path="/unauthorized" component={UnauthorizedPage} />
          <AnonymousRoute path="/cc_tokenization" component={CreditCardForm} />
          <Route path="/">
              {this.props.isSignedIn ? (
                  <Redirect to="/cc_tokenization" />
              ) : (
                  <Redirect to="/unauthorized" />
              )}
          </Route>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: true,
  };
};

export default connect(mapStateToProps, { tryLocalSignIn })(App);
