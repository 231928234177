const en = {
    translation:{
        error_messages: {
            access_denied:"Access Denied",
            access_denied_detail:"We could not find the page you were looking for.",
        },
        creditCardForm: {
            currency_name: "Currency",
            card_number: "Card Number",
            card_expiry: "Card Expiry (MM/YYYY)",
            card_holder_name: "Name on Card",
            card_holder_nickname: "Card Nickname",
            pay_accepting: "Pay Accepting Supplier",
            pay_accepting_detail: "Use your credit card to pay suppliers that accept credit card payments",
            pay_non_accepting: "Pay Non-Accepting Supplier",
            pay_non_accepting_detail: "Use your credit card to fund payments to suppliers that do not accpet credit card payments",
            personal_detail_formgroup: "Personal Details",
            street_number: "Street Number",
            street_name: "Street Name",
            city_name: "City",
            province_name: "Province",
            postal_code_name: "Postal Code",
            mobile_number_name: "Mobile Number",
            email_address_name: "Email Address",
            submit_button: "Submit",
            cancel_button: "Cancel"
        },
        validationErrors:{
            require_label: "Required",
            card_invalid_label: "Invalid {{cardType}} Card Number",
            email_invalid_label: "Invalid email address",
            expiry_invalid_label: "Invalid Card Expiry Date",
            postalcode_invalid_label: "Please enter the postal code in upper case. For E.g. M1M1M1",
            field_max_label: "Must not be more than {{max}} characters",
            phone_invalid_label: "Invalid phone number",
            suppliertype_invalid_label: "Atleast one type of supplier must be selected",
        },
        provincesList: {
            none: "None"
        }
    }
};
export default en;