import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./lang/en";
import fr from "./lang/fr";

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        //debug: true, for development env
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en, fr
        }
    });


export default i18n;